import React, { useState } from "react";
import { Button, Text, TextInput, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "./HealthStyles.css";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons from react-icons
import { useMediaQuery } from "@mantine/hooks";

import { IconUser, IconAt } from "@tabler/icons-react";
import { NativeSelect, rem } from "@mantine/core";
import { Modal } from "@mantine/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";

const Health = () => {
  const emailIcon = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const navigate = useNavigate();

  const handleSalesClick = () => {
    navigate("/sales");
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleCreatorsClick = () => {
    navigate("/creators");
  };

  const handleHealthClick = () => {
    navigate("/health");
  };

  //-----------------------------mantine button-----------------------------------------------//

  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Mobile screens
  const isMediumScreen = useMediaQuery("(max-width: 1024px)"); // Tablet screens

  let buttonSize;
  if (isSmallScreen) {
    buttonSize = "sm";
  } else if (isMediumScreen) {
    buttonSize = "md";
  } else {
    buttonSize = "xl";
  }
  //-----------------------------------------Form data-------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="bg-[#0f0f10] font-excon px-1 md:px-0">
        {/* first section */}
        <div>
          <div className=" section1-background-h">
            {/* Navbar */}
            <div className="pt-10">
              <div className="flex items-center justify-between px-4 py-1 mb-20 bg-[#232125] mx-4 md:mx-10 lg:mx-20 xl:mx-40 rounded-3xl border border-[#2e2c33]">
                {/* Logo */}
                <div className="ml-2 sm:ml-4">
                  <img
                    src="images/velara-logo.png"
                    alt="velara-logo"
                    className="w-full h-auto"
                  />
                </div>

                {/* Hamburger Icon for Mobile */}
                <div className="block lg:hidden">
                  <button
                    className={`${
                      isOpen ? "py-0" : "py-2"
                    } text-white focus:outline-none`}
                    onClick={toggleNavbar}
                  >
                    {isOpen ? (
                      <FiX className="w-6 h-6 transition-transform duration-300" />
                    ) : (
                      <FiMenu className="w-6 h-6 transition-transform duration-300" />
                    )}
                  </button>
                </div>

                {/* Mobile Navbar Items */}
                <div
                  className={`${
                    isOpen
                      ? "translate-y-0 opacity-100"
                      : "-translate-y-full opacity-0"
                  } fixed top-0 left-0 right-0 bg-[#232125] z-50 transition-all duration-500 ease-in-out lg:hidden h-auto max-h-[50vh] overflow-hidden rounded-b-3xl`}
                >
                  <div className="flex flex-col items-center justify-center py-6 space-y-6">
                    {/* Close Button */}
                    <div className="flex items-center justify-between w-full px-6">
                      <img
                        src="images/velara-logo.png"
                        alt="velara-logo"
                        className=""
                      />
                      <button className="text-white" onClick={toggleNavbar}>
                        <FiX className="w-8 h-8" />
                      </button>
                    </div>

                    {/* Navbar Items */}
                    <Button
                      variant="transparent"
                      size="xl"
                      color="#a8a8a8"
                      onClick={handleHomeClick}
                    >
                      Home
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleSalesClick}
                      color="#a8a8a8"
                    >
                      Sales Team
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleCreatorsClick}
                      color="#a8a8a8"
                    >
                      Creators
                    </Button>
                    <Button
                      variant="transparent"
                      size="xl"
                      onClick={handleHealthClick}
                      color="#a8a8a8"
                    >
                      Health Care
                    </Button>
                  </div>
                </div>

                {/* Centered buttons (visible on large screens) */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:items-center lg:space-x-2">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="#a8a8a8"
                    onClick={handleHomeClick}
                  >
                    Home
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleSalesClick}
                    color="#a8a8a8"
                  >
                    Sales Team
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleCreatorsClick}
                    color="#a8a8a8"
                  >
                    Creators
                  </Button>
                  <Button
                    variant="transparent"
                    size="xl"
                    onClick={handleHealthClick}
                    color="#a8a8a8"
                  >
                    Health Care
                  </Button>
                </div>
              </div>
            </div>

            {/* Main section */}
            <div className="flex flex-col items-center space-x-0 text-center lg:space-x-10">
              <div className="mt-2 md:mt-4 lg:mt-8">
                <h1 className="mb-6 text-2xl font-semibold text-white md:leading-tight lg:leading-tight sm:text-3xl md:text-4xl lg:text-5xl">
                  Streamline your
                  <span className="text-[#b28efa]"> patient care </span>
                </h1>
                <p className="text-lg sm:text-xl md:text-2xl text-[#b7b5be] mt-4">
                  Transforming Patient Care with the power of AI{" "}
                </p>

                <div className="flex items-center justify-center mt-8 md:mt-12">
                  <Button
                    variant="filled"
                    size={buttonSize}
                    radius="md"
                    color="#b28efa"
                    onClick={open}
                  >
                    Join the waitlist
                  </Button>
                </div>
              </div>

              <div className="flex justify-center w-full  md:w-[65%]">
                <img
                  src="images/health-section1-1.png"
                  alt="velara"
                  className="w-full h-auto max-w-none"
                />
              </div>
            </div>
          </div>
        </div>

        {/* second section */}
        <div className="flex flex-col items-center justify-center px-4 py-10 space-y-10 md:flex-row md:py-16 lg:py-20 md:space-y-0 md:space-x-20 lg:space-x-40 section2-background-h">
          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/health-section2-1.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
          <div className="flex flex-col space-y-6 text-left md:space-y-8 lg:space-y-10 w-[40vh] md:w-[70vh] ">
            <h1 className="text-2xl leading-tight text-left text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Built for Healthcare Providers by
              <span className="text-[#b28efa]"> Healthcare Experts</span>
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-left text-[#b5b4b8] leading-normal  md:leading-normal lg:leading-normal">
              We’re working closely with an advisory board comprising of
              doctors, nurses, and administrators to ensure safety first
              approach, and to make sure our AI fits seamlessly into your
              workflow.
            </h1>
          </div>
        </div>

        {/* third section */}
        <div className="flex flex-col-reverse items-center justify-center px-4 py-10 space-y-10 md:flex-row md:py-16 lg:py-20 md:space-y-0 md:space-x-20 lg:space-x-40 ">
          <div className="flex flex-col space-y-6 text-left md:space-y-8 lg:space-y-10 w-[40vh] md:w-[70vh]">
            <h1 className="text-2xl leading-tight text-left text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              <span className="text-[#b28efa]"> Clinical Insights</span> for
              Informed Decisions at{" "}
              <span className="text-[#b28efa]"> Critical Care</span> Moments
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-left text-[#b5b4b8] leading-normal  md:leading-normal lg:leading-normal">
              Our AI-driven platform analyzes complex medical data in real-time,
              offering healthcare professionals the insights they need to make
              informed decisions when it matters most. The result? Improved
              patient outcomes and more efficient care.
            </h1>
          </div>
          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/health-section3-1.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* forth section */}
        <div className="flex flex-col items-center justify-center px-4 py-10 space-y-10 md:flex-row md:py-16 lg:py-20 md:space-y-0 md:space-x-20 lg:space-x-40">
          <div className="py-10 sm:py-14 md:py-20">
            <img
              src="images/health-section4-1.png"
              alt="rocket"
              className="w-full h-auto"
            />
          </div>
          <div className="flex flex-col space-y-6 text-left md:space-y-8 lg:space-y-10 w-[40vh] md:w-[75vh]">
            <h1 className="text-2xl leading-tight text-left text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-tight lg:leading-tight">
              Enhance Patient{" "}
              <span className="text-[#b28efa]">Engagement </span> &
              <span className="text-[#834CF1]"> Empowerment </span>
            </h1>

            <h1 className="max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl text-base sm:text-lg md:text-xl lg:text-2xl text-left text-[#b5b4b8] leading-normal  md:leading-normal lg:leading-normal">
              Our AI-powered platform enables more personalized and efficient
              patient care, empowering patients to take an active role in their
              healthcare journey. Through better communication and
              patient-centered technology, your team can foster stronger patient
              relationships.
            </h1>
          </div>
        </div>

        {/* sixth section */}
        <div className="py-32 sm:py-40 md:py-48 lg:py-64 section5-background-h">
          <div className="space-y-6 sm:space-y-8 md:space-y-10">
            <h1 className="text-2xl text-center text-white sm:text-3xl md:text-4xl lg:text-5xl md:leading-snug lg:leading-snug">
              Ready to Transform Your Patient Care?
            </h1>
          </div>
          <div className="flex items-center justify-center py-5 sm:py-7 md:py-8 lg:py-10">
            <Button
              variant="filled"
              size={buttonSize}
              radius="md"
              color="#b28efa"
              onClick={open}
            >
              Join the waitlist
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <TextInput
            leftSectionPointerEvents="none"
            variant="filled"
            size="xl"
            radius="lg"
            leftSection={emailIcon}
            placeholder="Email"
            className="md:w-[35rem]"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
          />

          <Button
            variant="filled"
            size="lg"
            radius="md"
            style={{ color: "white" }}
            color="#7b5aff"
            className=""
            onClick={handleSubmit}
          >
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Health;
